.containerItem {
    height: 200;
    display: flex;
    align-items: center;
    justify-content: center;
    vertical-align: middle;
}
.vertical-center {
    min-height: 100%;  /* Fallback for browsers do NOT support vh unit */
    min-height: 100vh; /* These two lines are counted as one :-)       */
  
    display: flex;
    align-items: center;
  }
.containerOut {
height: 100%;

}
.headerItem{
    width: 100%;
    margin-top: 10;
    margin-left: 10;
    margin-bottom: 10;
    margin-right: 10;
}