.full-screen-popup {
    background-color: rgba(0,0,0,.62);
}

.d-block {
    display: block!important;
}
.modal {
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 1050;
    display: none;
    outline: 0;
}